import type { role } from '@/interfaces/users';
import routes from '@/routes';

export const notLoggedPath = [
  routes.pages.login(),
  routes.pages.reset(),
  routes.pages.password(),
  routes.pages.register(),
];

const securePath = {
  business: [],
  // business: [routes.pages.prospect()],
  pro: [routes.pages.cv()],
  free: [
    routes.pages.favoris(),
    routes.pages.paidSuccess(),
    routes.pages.searchFavoris(),
    routes.pages.searches(),
    routes.pages.pro(),
    routes.pages.reseauxResult(),
  ],
  public: [
    routes.pages.login(),
    routes.pages.home(),
    routes.pages.profil(),
    routes.pages.reset(),
    routes.pages.password(),
    routes.pages.register(),
    routes.pages.billing(),
    routes.pages.unauthorized(),
    routes.pages.signOut(),
  ],
  roles: ['admin', 'business', 'pro', 'free'],
};

export function publicAccess(route: string) {
  return securePath.public.some(
    (publicRoute) =>
      route === publicRoute || route.startsWith(publicRoute + '/'),
  );
}

export function freeAccess(route: string) {
  return securePath.free.some(
    (freeRoute) =>
      route === freeRoute ||
      route.startsWith(freeRoute + '/') ||
      publicAccess(route),
  );
}

export function proAccess(route: string) {
  return securePath.pro.some(
    (proRoute) =>
      route === proRoute ||
      route.startsWith(proRoute + '/') ||
      freeAccess(route),
  );
}

export function businessAccess(route: string) {
  return securePath.business.some(
    (businessRoute) =>
      route === businessRoute ||
      route.startsWith(businessRoute + '/') ||
      proAccess(route),
  );
}

export const onlyForRole = (UserRole: role, role: role) => {
  if (!role) {
    return false;
  }
  if (role === 'admin') {
    return true;
  }
  const priority = securePath.roles.indexOf(role);
  const currentPriority = securePath.roles.indexOf(UserRole);

  if (priority === -1 || currentPriority === -1) {
    return false;
  }
  return currentPriority <= priority;
};

const access = {
  freeAccess,
  proAccess,
  businessAccess,
};

export default function haveAccess(route: string, role: role): boolean {
  if (!role) {
    return false;
  }

  if (role === 'admin') {
    return true;
  }

  const accessName = `${role}Access`;

  if (accessName in access) {
    const accessFunction = access[accessName as keyof typeof access];

    return accessFunction(route);
  }

  return false;
}
